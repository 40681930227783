<template>
  <v-layout wrap class="form-menu">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-bookmark</v-icon>
      <v-toolbar-title>
        {{ $t(`booking.reservations.${isNew ? 'addReservation' : 'editReservation'}`, locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-flex xs12>
      <owner-pax-form-item
        v-if="formFields && formFields.owner"
        :formFields="formFields.owner"
        :isOwner="true"
        :onChangeValues="handleChangeValues"
      />
    </v-flex>
    <v-flex xs12 v-if="pax && pax.length > 0">
      {{ $t('booking.reservations.paxData', locale) }} :
    </v-flex>
    <v-flex xs12 v-if="pax && pax.length > 0">
      <owner-pax-form-item
        v-for="(p, index) of pax"
        :key="`pax${index}`"
        :formFields="p"
        :pos="index"
        :onDeletePax="handleDeletePax"
        :onChangeValues="handleChangeValues"
      />
    </v-flex>
    <v-flex xs12 style="margin: 10px">
      <v-btn color="primary" @click="handleAddPax">
        {{ $t('booking.reservations.addPax', locale) }}
      </v-btn>
    </v-flex>
    <v-flex xs12>
      <action-buttons
        :id="editedID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
      />
    </v-flex>
  </v-layout>
</template>
<script>
const defaultValues ={
  owner: [],
  pax: {},
}
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import ActionButtons from '@/components/ActionButtons'
import OwnerPaxFormItem from './OwnerPaxFormItem'
export default {
  name: 'reservationForm',
  components: {
    ActionButtons,
    OwnerPaxFormItem,
  },
  props: {
    editedID: {
      type: String,
      default: null,
    },
    event: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      required: true
    },
  },
  data () {
    return {
      pax: [],
      loading: false,
      image: null,
      showSelectCustomer: false,
      formFields: [],
      values: defaultValues,
      oldNumPax: null, // only for updateItem
    }
  },
  computed: {        
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('booking')
    },
    isNew () {
      return this.editedID === 'new'
    },
  },
  watch: {
    event () {
      this.handleGetData()
      //this.prepareFormFields()
    },
    editedID () {
      this.handleGetData()
      //this.handleGetItem()
    },
    'editedItem.AllowReservations' (v) {
      if (!v) this.editedItem.Seats = null
    },
  },
  mounted () {
    //this.formFields = []
    this.handleGetData()
    //if (this.event) this.prepareFormFields()
    //if (this.editedID) this.handleGetItem()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareField (v) {
      if(v.type === 'select' && v.items && v.items.length > 0) {
        v.items = v.items.map(item => {
          item.name = utils.getTranslation(item.name, this.locale)
          return item
        })
      }
      return v
    },
    handleGetData () {
      this.loading = true
      this.formFields = []
      this.values = defaultValues
      if (!this.event || !this.editedID) return

      // get form fields by eventID
      api.getAllWithoutLimit ('booking', `v1/public/events/${this.event.ID}/${this.event.IsActtiv ? 'form-fields-acttiv' : 'form-fields' }`)
        .then(response => {
          if(!response) {
            this.loading = false
            return
          }

          if (response.owner) response.owner = response.owner.map(item => {
            item = this.prepareField(item)
            return item
          })
          if (response.pax) response.pax = response.pax.map(item => {
            item = this.prepareField(item)
            return item
          })
          //this.formFields = response
          this.handleGetItem(response)
        })
    },
    handleGetItem (formFields) {
      if (this.isNew) {
        this.formFields = formFields
        this.loading = false
        return
      }
      this.pax = []
        api.getItem ('booking', 'v1/private/reservations/', this.editedID)
          .then(response => {
            this.values = JSON.parse(response.FieldsValues)

            formFields.owner.map(item => {
              if (this.values.owner[item.key]) item.defaultValue = this.values.owner[item.key] // set default value with edited data
              return item
            })
            this.formFields = formFields
            this.oldNumPax = response.NumPax
            for (let i = 0; i < this.oldNumPax; i++) this.handleAddPax(this.values.pax[i])
            
            this.loading = false
          })
        
    },
    handleAddPax (v) {
      if (!this.formFields.pax) return

      const formFieldsPax = JSON.parse(JSON.stringify(this.formFields.pax))

      if (v) for (const ff of formFieldsPax) if (v[ff.key]) ff.defaultValue = v[ff.key] // set default value with edited data
    
      this.pax.push(formFieldsPax)
    },
    handleDeletePax(v) {
      this.pax.splice(v, 1)
    },
    handleChangeValues (isOwner, v, pos) {
      if (isOwner) this.values.owner = v
      else this.values.pax[pos] = v

      this.values.numPax = this.pax.length

      this.handleChangeIsModified()
    },
    handleChangeIsModified () {
    },
    checkValues () {
      // PAX
      for (let i = 0; i < this.pax.length; i++) {
        for (const field of this.formFields.pax.filter(x => x.required)) {
          if (!this.values.pax[i]) return false
          const value = this.values.pax[i][field.key]
          if (!value || value.length === 0) return false
        }
      }
      // OWNER
      for (const field of this.formFields.owner.filter(x => x.required)) {
        const value = this.values.owner[field.key]
        if (!value || value.length === 0) return false
      }

      return true
    },
    handleSave () {

      if (!this.pax || this.pax.length === 0) {
        alert ('Debe existir al menos un participante')
        return
      }
      if (!this.checkValues()) {
        alert('Faltan campos')
        return
      }
      const body = {
        event: {
          EventID: this.event.ID, 
          Source: this.event.IsActtiv ? 'acttiv' : 'contentmanager', 
          WorkspaceID: this.workspaceID,
          ScheduleID: this.event.ScheduleID, 
          ActivityID: this.event.ActivityID, 
          ActivityName: this.event.ActivityName, 
          DateTime: this.event.DateTime, 
          Date: this.event.Date, 
          Time: this.event.Time, 
          Duration: this.event.Duration, 
          Free: this.event.Free, 
          Book: this.event.Book, 
          Cancel: this.event.Cancel,
        },
        values: this.values,
        oldNumPax: this.oldNumPax,
      }

      console.log('123', body)

      if (this.isNew)
        api.addItem ('booking', `v1/private/reservations/`, body)
          .then((response) => {
            console.log('response', response)
            this.onClose('add', response.data)
          })
          .catch(error => {
            console.log('error', error)
          })
      else 
        api.updateItem ('booking', `v1/private/reservations/`, this.editedID , body)
          .then((response) => {
            console.log('response', response)
            this.onClose('add', response.data)
          })
    },
    handleDelete () {
      api.deleteItem('booking', `v1/private/reservations/`, this.editedID)
        .then(response => {
          if(response && response.data.status === 'OK') this.onClose(true)
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  }
}
</script>
<style scoped>
.form-menu .availability {
  background-color: #f3f0f0;
  border-radius: 5px;
  padding: 0 20px 20px 20px;
  margin-top: 15px;
}
.form-menu .common-forms {
  padding: 0 10px 10px 10px;
}
</style>


